<template>
  <div class="tab block lg:flex lg:justify-start text-center">
    <!--tab siti web-->
    <div class="pr-0 lg:pr-12 relative">
      <h3 v-if="!isMobile" @click.prevent="selectTab(tabs.site)" :class="tab == tabs.site ? 'text-blue' : ''" class="hover:text-blue text-tab font-bold cursor-pointer uppercase">Siti web</h3>
      <transition name="slide-down">
        <div v-if="tab == tabs.site || isMobile"  class="tab-item bg-bluegrey lg:absolute w-100 grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-sm mr-12 overflow-hidden shadow-2xl z-30">
          <router-link tag="div" :to="{ name: 'Websites' }" class="tab-item-col col-span-1 flex flex-col cursor-pointer">
            <div class="pt-10 pb-8 lg:px-8 px-12 bg-white">
              <h3 v-if="isMobile" class="text-lg font-bold mb-3 text-blue">Siti web</h3>
              <p class="mb-0">Il web è uno spazio virtuale da abitare ed esplorare. Un'avventura degna di ogni esploratore audace e coraggioso.</p>
              <p v-if="isMobile" class="mb-0 text-caption text-blue font-bold mt-6">Scopri i siti web</p>
            </div>
            <div style="background-image: url('/img/siti-web.png');" class="h-full image-container"></div>
            <!--<img class="w-full" src="/img/siti-web.png" alt="Siti web immagine copertina">-->
          </router-link>
          <div v-if="!isMobile" class="tab-item-col col-span-2 ">
            <div class="py-10 px-8 column-count-2">
              <ul>
                <router-link tag="li" class="text-n cursor-pointer" v-for="(website,i) in websites" :key="i" :to="{ name: 'Website.show', params: {slug: website.id} }">{{website.name}}</router-link>
                <router-link tag="li" class="font-bold text-n text-blue cursor-pointer" :to="{ name: 'CustomWebsite' }">Nessuna delle precedenti</router-link>
              </ul>
            </div>
          </div>
          <router-link tag="button" :to="{ name: 'Websites' }" class="absolute right-0 bottom-0 mr-4 mb-4">
            <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
          </router-link>

        </div>
      </transition>
    </div>
    <!--tab app mobile-->
    <div class="pr-0 lg:pr-12 relative">
      <h3 v-if="!isMobile" @click.prevent="selectTab(tabs.app)" :class="tab == tabs.app ? 'text-salmon' : ''" class="hover:text-salmon text-tab font-bold cursor-pointer uppercase">App Mobile</h3>
      <transition name="slide-down">
        <router-link tag="div" :to="{ name: 'MobileApps' }" v-if="tab == tabs.app || isMobile" class="z-30 tab-item lg:absolute flex flex-col max-w-sm mr-12 overflow-hidden shadow-2xl cursor-pointer">
          <div class="pt-10 lg:px-8 px-12 pb-12 relative">
            <h3 v-if="isMobile" class="text-lg font-bold mb-3 text-salmon">App mobile</h3>
            <p class="mb-4">Le app mobile offrono un'ottima opportunità per fornire servizi ai propri clienti e per rimanere in contatto con loro, ovunque egli siano.</p>
            <p v-if="isMobile" class="mb-0 text-caption text-salmon font-bold mt-6">Scopri le app</p>
            <!--<p class="text-right mt-4">-->
            <router-link v-if="!isMobile" tag="button" :to="{ name: 'MobileApps' }"  class="absolute right-0 bottom-0 mr-4 mb-4">
              <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
            </router-link>
            <!--</p>-->
          </div>
          <div style="background-image: url('/img/app-mobile.png');" class="h-full image-container">
          </div>
          <router-link v-if="isMobile" tag="button" :to="{ name: 'MobileApps' }" class="absolute right-0 bottom-0 mr-4 mb-4">
            <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
          </router-link>
          <!--<img class="w-full" src="/img/app-mobile.png" alt="App mobile immagine copertina">-->
        </router-link>
      </transition>
    </div>
    <!--tab web app-->
    <div class="pr-0 lg:pr-12 relative">
      <h3 v-if="!isMobile" @click.prevent="selectTab(tabs.wapp)" :class="tab == tabs.wapp ? 'text-darth' : ''" class="hover:text-darth text-tab font-bold cursor-pointer uppercase">Web App</h3>

      <transition name="slide-down">
        <router-link tag="div" :to="{ name: 'WebApps' }" v-if="tab == tabs.wapp || isMobile" class="z-30 tab-item lg:absolute flex flex-col max-w-sm mr-12 overflow-hidden shadow-2xl cursor-pointer">
          <div class="pt-10 lg:px-8 px-12 pb-12 relative">
            <h3 v-if="isMobile" class="text-lg font-bold mb-3 text-darth">Web app</h3>
            <p class="mb-4">Le web app sono strumenti versatili e potenti che offrono numerose funzionalità personalizzate per gestire ogni aspetto del tuo business.</p>
            <p v-if="isMobile" class="mb-0 text-caption text-darth font-bold mt-6">Scopri le web app</p>
            <!--<p class="text-right mt-4">-->
            <router-link v-if="!isMobile" tag="button" :to="{ name: 'WebApps' }" class="absolute right-0 bottom-0 mr-4 mb-4">
              <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
            </router-link>
            <!--</p>-->
          </div>
          <div style="background-image: url('/img/web-app.png');" class="h-full image-container">
          </div>
          <router-link v-if="isMobile" tag="button" :to="{ name: 'WebApps' }" class="absolute right-0 bottom-0 mr-4 mb-4">
            <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
          </router-link>
          <!--<img class="w-full" src="/img/web-app.png" alt="App mobile immagine copertina">-->
        </router-link>
      </transition>
    </div>

    <!--tab webGL-->
    <div class="pr-0 lg:pr-12 relative">
      <h3 v-if="!isMobile" @click.prevent="selectTab(tabs.webgl)" :class="tab == tabs.webgl ? 'text-cyan' : ''" class="hover:text-cyan text-tab font-bold cursor-pointer uppercase">3D webGL</h3>
      <transition name="slide-down">
        <router-link tag="div" :to="{ name: 'ThreeD' }" v-if="tab == tabs.webgl || isMobile" class="z-30 tab-item lg:absolute flex flex-col max-w-sm mr-12 overflow-hidden shadow-2xl cursor-pointer">
          <div class="pt-10 lg:px-8 px-12 pb-12 relative">
            <h3 v-if="isMobile" class="text-lg font-bold mb-3 text-cyan">3D webGL</h3>
            <p class="mb-4">Scopri le potenzialità della programmazione 3D sul web, trasforma le tue pagine web in un'esperienza unica.</p>
            <p v-if="isMobile" class="mb-0 text-caption text-cyan font-bold mt-6">Scopri le webGL 3D</p>
            <!--<p class="text-right mt-4">-->
            <router-link v-if="!isMobile" tag="button" :to="{ name: 'ThreeD' }"  class="absolute right-0 bottom-0 mr-4 mb-4">
              <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
            </router-link>
            <!--</p>-->
          </div>
          <div style="background-image: url('/img/webgl.png');" class="h-full image-container">
          </div>
          <router-link v-if="isMobile" tag="button" :to="{ name: 'ThreeD' }" class="absolute right-0 bottom-0 mr-4 mb-4">
            <heroicon stroke="#000" stroke-width="2" width="35" @click="showForm = !showForm" name="arrow-right"></heroicon>
          </router-link>
          <!--<img class="w-full" src="/img/app-mobile.png" alt="App mobile immagine copertina">-->
        </router-link>
      </transition>
    </div>

  </div>
</template>

<script>
  // import { db } from '../db'
  import { mapGetters } from 'vuex'
  import { isMobile } from 'mobile-device-detect';


  export default {
    name: "TabService",
    data() {
      return {
        tab: 'webSite',
        tabs: {
          site: 'webSite',
          app: 'mobileApp',
          wapp: 'webApp',
          webgl: 'webGL'
        },
        isMobile: isMobile
        // websites: [],
      }
    },
    computed: {
      ...mapGetters({
        websites: 'websites'
      })
    },
    // firestore: {
    //   websites: db.collection('websites').orderBy('name'),
    // },
    methods: {
      selectTab(tab) {
        this.tab = tab;
        this.$emit('change-tab',tab)
      },
      resizeWindow() {
        //console.log(isMobile)
        this.isMobile = isMobile
      }
    },
    created() {
      //console.log('is mobile: '+isMobile);
      window.addEventListener("resize", this.resizeWindow);
    }
  }
</script>

<style scoped>

  .tab {
    padding-bottom: 660px;
  }

  .relative {
    padding-bottom: 30px;
  }

  .relative h2 {
    cursor: pointer;
  }

  .tab-item {
    /*position: absolute;*/
    top: 100%;
    min-width: 366px;
    background-color: white;
  }

  .w-100 {
    min-width: 1100px;
  }

  .tab-item p {
    font-size: 17px;
    line-height: 1.8em;
  }

  .column-count-2 {
    column-count: 2;
  }

  .column-count-2 li {
    line-height: 45px;
  }

  .image-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 300px;
  }

  .bg-bluegrey {
    background-color: #FBFBFB;
  }

  .slide-down-enter-active {
    transition: all .3s ease;
  }
  .slide-down-leave-active {
    transition: all .3s ease;
  }
  .slide-down-enter, .slide-down-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-80px);
    opacity: 0;
  }

  @media only screen and (min-width: 780px) and (max-width: 1200px) {
    .w-100 {
      min-width: 900px;
    }
  }

  @media only screen and (max-width: 779px) {
    .w-100 {
      min-width: 100%;
    }

    .tab-item {
      position: relative;
      min-width: 100%;
      margin-bottom: 50px;
    }

    .tab {
      padding-bottom: 50px;
    }
  }

</style>