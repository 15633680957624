<template>
  <div class="relative">
    <!--<h2 class="text-xl lg:text-title font-bold mb-12 lg:mb-24">Fidati di chi si è fidato</h2>-->

    <carousel @pagechange="change()" class="mb-12 lg:mb-16 carousel" paginationActiveColor="#FFB800" :adjustableHeight="isMobile" :paginationEnabled="isMobile" :touchDrag="true" :scrollPerPage="false" :perPageCustom="[[350, 1], [768, 1], [1024, 2]]" :resistanceCoef="50" >
      <slide :data-index="i" v-for="(review,i) in reviews" :key="review.id" class="slide">
        <h3 class="font-bold text-lg">{{ review.name }}</h3>
        <p class="my-6">
          <heroicon class="inline mr-2" v-for="star in review.stars" :key="star" stroke="#FFB800" fill="#FFB800" stroke-width="0" name="star"></heroicon>
        </p>
        <div class="text-caption" v-html="excerpt(review.body)"></div>
        <!--<p class="text-caption">{{ review.body | slice }}</p>-->
      </slide>

    </carousel>

    <p>
      <router-link class="text-yellow font-bold text-lg" tag="a" :to="{ name: 'Reviews' }">Leggi tutte le recensioni <heroicon class="mt-2" stroke="#000" stroke-width="2" width="35" name="arrow-right"></heroicon></router-link>
    </p>


  </div>
</template>

<script>
  // import { db } from '../db'
  import { Carousel, Slide } from 'vue-carousel';
  import { mapGetters } from 'vuex'
  import { isMobile } from 'mobile-device-detect';

  export default {
    name: "ReviewsCarousel",
    components: {
      Carousel,
      Slide
    },
    data() {
      return {
        isMobile: isMobile,
      }
     },
    filters: {
      slice: (val) => {

        const maxLenght = 250;

        //console.log(val.replace(/(<([^>]+)>)/gi, ''));

        if(val.replace(/(<([^>]+)>)/gi, '').split('').length > maxLenght) {

          return val.slice(0, maxLenght) + '...';
        } else {
          return val;
        }
      }
    },
    computed: {
      ...mapGetters({
        reviews: 'reviews'
      })
    },
    methods: {
      change(page) {
        // console.log('page-number: '+page);
      },
      excerpt(content) {
        const maxLength = 235;

        let excerpt
        let currentLength = 0;
        let i;
        let inTag = false;
        let currentTag = ''
        let deep = 0
        const toClose = []

        for (i = 0; i < content.length; i++) {

          if(content[i] === '<' ) {
            inTag = true;
            currentTag += content[i];

            if(content[i+1] !== '/') {
              deep += 1
            }
          } else if( content[i] === '>' ) {
            currentTag += content[i];
            inTag = false;
            if(currentTag.includes('/')) {
              toClose[deep] = null
              deep = deep - 1;
            } else {
              toClose[deep] = currentTag
            }
            currentTag = '';
          } else if (inTag) {
            currentTag += content[i];
          }  else if(!inTag) {
            if(currentLength >= maxLength) {
              break
            }
            currentLength += 1;
          }

        }


        //il contenuto è minore della lunghezza massima
        if(deep == 0) {
          excerpt = content;
        } else {
          excerpt = content.slice(0, i) + '...'
          for(let i = deep; i > 0; i-- ) {
            excerpt += this.checkHtmlTag(toClose[i]);
          }
        }

        return excerpt;

      },
      checkHtmlTag(tag) {
        if(tag.includes('<p')) {
          return '</p>';
        } else if( tag.includes('<a') ) {
          return '</a>';
        } else if( tag.includes('<strong') ) {
          return '</strong>';
        } else if( tag.includes('<ul') ) {
          return '</ul>';
        } else if( tag.includes('<li') ) {
          return '</li>';
        } else if( tag.includes('<i') ) {
          return '</i>';
        } else if( tag.includes('<i') ) {
          return '</i>';
        } else if( tag.includes('<span') ) {
          return '</span>';
        } else {
          return null
        }
      }
    },
    // firestore: {
    //   reviews: db.collection('reviews').orderBy('stars'),
    // },
  }
</script>

<style scoped>

  .slide {
    padding-right: 110px;
    cursor: -webkit-grab; cursor: grab;
  }

  /*.slide:target {*/
    /*cursor: -webkit-grabbing; cursor: grabbing;*/
  /*}*/

  @media only screen and (max-width: 768px) {
    .slide {
      padding-right: 0px;
    }
  }
</style>