<template>
  <div>

    <p><strong>Ho ascoltato le loro idee e i loro bisogni</strong> e gli ho proposto le soluzioni più adatte.</p>
    <p class="mb-6 lg:mb-8">Poi li ho accompagnati passo passo in tutte le fasi di sviluppo.</p>
    <h2 class="text-xl lg:text-title font-bold mb-10">Ecco perchè i miei clienti sono soddisfatti.</h2>
    <p class="mb-12 lg:mb-24">Questo è ciò che caratterizza il mio lavoro:</p>

    <div class="w-100 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-12 sm:gap-8 mt-12">
      <div class="col-span-1 keyword-card" v-for="keyword in keywords" :key="keyword.id">
        <img class="keyword-icon" :src="keyword.icon ? keyword.icon : '/img/keyword-icon.png'" alt="Icona keyword">

        <h3 class="font-bold text-caption py-6">{{ keyword.name }}</h3>

        <p class="text-button mb-12">{{ keyword.body }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Keywords",
    computed: {
      ...mapGetters({
        keywords: 'keywords'
      })
    }
  }
</script>

<style scoped>

  .keyword-icon {
    width: 80%;
    max-width: 200px;
  }


  .keyword-card p {
    max-width: 265px;
  }

  @media only screen and (max-width: 780px) {
    .keyword-icon {
      width: 80%;
      max-width: 150px;
    }

    .keyword-card p {
      max-width: 100%;
    }
  }
</style>