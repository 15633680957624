<template>
  <div class="modal-container fixed w-screen left-0 bottom-0 pb-0 md:pb-10">
    <transition name="slide-up">
      <div v-if="fired" class="modal flex relative max-w-sm bg-white overflow-hidden shadow-2xl menu-card px-10 py-12">

        <div class="pt-4 w-full">
          <h3 :class="type === 'success' ? 'text-green' : 'text-orange'" class="font-bold text-caption mb-3">{{ type === 'success' ? 'Successo!' : 'Ops, c\'è un errore :(' }}</h3>
          <p class="text-button">{{body}}</p>
          <p v-if="regards" class="font-bold text-button">{{ type === 'success' ? 'Grazie!' : 'Mi dispiace :(' }}</p>
        </div>
        <div class="text-right mb-1 cursor-pointer ml-4">
          <heroicon @click="fireOff" height="20" width="21" viewBox="0 0 21 20" fill="#000" stroke-width="0" name="x"></heroicon>
        </div>

        <div :class="type === 'success' ? 'bg-green' : 'bg-orange'" class="absolute timing-bar bottom-0 left-0"></div>

      </div>
    </transition>

  </div>

</template>

<script>
  export default {
    name: "Modal",
    props: {
      body: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'success'
      },
      time: {
        type: Number,
        default: 5000
      },
      fire: {
        type: Boolean,
        default: false
      },
      regards: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        types: ['success','error','info'],
        interval: null,
        fired: false,
      }
    },
    watch: {
      fire: function(fire) {
        if(fire) {
          this.fireOn();
        } else {
          this.fireOff();
        }
      }
    },
    methods: {
      fireOn() {
        this.fired = true
        this.interval = setInterval(this.fireOff,this.time);
      },
      fireOff() {
        this.fired = false
        this.$emit('closed');
        clearInterval(this.interval);
      }
    },
  }
</script>

<style scoped>
  .modal-container {
    pointer-events: none;
  }

  .modal {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    pointer-events: all;
    z-index: 99999;
  }

  .timing-bar {
    height: 4px;
    width: 100%;
  }

  .slide-up-enter-active {
    transition: all .6s ease;
  }
  .slide-up-leave-active {
    transition: all .6s ease;
  }
  .slide-up-enter, .slide-up-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(100px);
    opacity: 0;
  }
</style>