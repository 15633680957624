<template>
  <div class="h-full w-full">
    <vgl-renderer camera="c" scene="scene" class="canvas" antialias>
      <!--<vgl-box-geometry name="box"></vgl-box-geometry>-->
      <vgl-mesh-phong-material name="mat" ref="mat" color="0xffffff" shininess="0.2"></vgl-mesh-phong-material>
      <vgl-sphere-geometry name="sphere" radius="5" width-segments="32" height-segments="32"></vgl-sphere-geometry>
      <vgl-scene background-color="#A9E7DA" name="scene">
        <vgl-directional-light color="#73174d" intensity="0.5" position="200 300 400"></vgl-directional-light>
        <vgl-directional-light color="#73174d" intensity="0.5" position="-200 300 400"></vgl-directional-light>
        <vgl-hemisphere-light ground-color="#3a2de6" color="#ffffff" intensity="0.6"></vgl-hemisphere-light>
        <vgl-mesh v-for="(o,i) in objects" :key="i" geometry="sphere" material="mat" :position="vector3Factory(o.position.x,o.position.y,o.position.z)"></vgl-mesh>
      </vgl-scene>
      <vgl-perspective-camera name="c"
                              :near="camera.near"
                              :far="camera.far"
                              :fov="camera.fov"
                              :position="vector3Factory(camera.position.x+clientX/100,camera.position.y,camera.position.z-clientX/100)"
                              :rotation="eulerFactory(camera.rotation.x-(clientY/200),camera.rotation.y+(clientX/40),camera.rotation.z)"></vgl-perspective-camera>
    </vgl-renderer>
  </div>
</template>

<script>

  import * as THREE from 'three'
  import { gsap } from 'gsap'
  import _ from 'lodash'
  import {
    VglRenderer,
    VglScene,
    VglPerspectiveCamera,
    VglSphereGeometry,
    VglMesh,
    VglMeshPhongMaterial,
    VglHemisphereLight,
    VglDirectionalLight
  } from 'vue-gl';

  export default {
    name: "SphereSlide",
    components: {
      VglRenderer,
      VglScene,
      VglPerspectiveCamera,
      VglSphereGeometry,
      VglMesh,
      VglMeshPhongMaterial,
      VglHemisphereLight,
      VglDirectionalLight
    },
    props:{
      clientX: {
        type: Number,
        default: 0,
      },
      clientY: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        el: '.canvas',
        canvas: null,
        camera: {
          near: 1,
          far: 1000,
          fov: 45,
          position: {
            x: 0,
            y: 0,
            z: 25,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          }
        },
        perspectiveCamera: null,
        geometry: null,
        light: null,
        materials: [],
        geometries: [],
        objects: [],
        speed: 1,
        tl: null,
        opacity: 0,
        step: 1,
      }
    },
    watch: {
      speed: function(s) {
        this.tl.timeScale(s)
      },
      opacity: function(o, material) {
        material.opacity = o;
      }
    },
    computed: {
      iteration: function() {
        return this.tl.iteration();
      },
      materialKey: function() {
        return this.opacity+'_material_opacity'
      }
    },
    methods: {
      vector3Factory(x,y,z) {
        return new THREE.Vector3(x,y,z);
      },
      eulerFactory(x,y,z) {
        return new THREE.Euler(x*(Math.PI/180),y*(Math.PI/180),z*(Math.PI/180),'XYZ');
      },
      resize() {
        this.perspectiveCamera.aspect = window.innerWidth / window.innerHeight;
        this.perspectiveCamera.updateProjectionMatrix();
        // renderer.setSize( window.innerWidth, window.innerHeight );
      },
      onWheel: _.throttle(function() {

        switch (this.step) {
          case 1:
            gsap.to(this.$data.camera.position, {ease: "sine.inOut", duration: 3, x: 40, z: -40,})
            gsap.to(this.$data.camera.rotation, {ease: "sine.inOut", duration: 3, x: 0, y: 90,})
            this.step++
            break
          case 2:
            gsap.to(this.$data.camera.position, {ease: "sine.inOut", duration: 3, x: 60, y: -20, z: -20,})
            gsap.to(this.$data.camera.rotation, {ease: "sine.inOut", duration: 3, x: 0, y: 90, z: 90})
            this.step++
            break
          case 3:
            gsap.to(this.$data.camera.position, {ease: "sine.inOut", duration: 3, x: 10, y: 0, z: -100, })
            gsap.to(this.$data.camera.rotation, {ease: "sine.inOut", duration: 3, x: 0, y: 165, z: 0,})
            this.step++
            break
          case 4:
            gsap.to(this.$data.camera.position, {ease: "sine.inOut", duration: 3, x: 0, y: 0, z: 25, })
            gsap.to(this.$data.camera.rotation, {ease: "sine.inOut", duration: 3, x: 0, y: 0, z: 0,})
            this.step = 1
            break
          default:
            gsap.to(this.$data.camera.position, {ease: "sine.inOut", duration: 3, x: 0, y: 0, z: 25, })
            gsap.to(this.$data.camera.rotation, {ease: "sine.inOut", duration: 3, x: 0, y: 0, z: 0,})
            this.step = 1
        }

      }, 3000, { 'trailing': false }),
      addSphere(position) {
        const name = 'sphere_'+ (this.objects.length + 1);
        const materialName = 'mat_'+name;
        const geometryName = 'geom_'+name

        this.materials[materialName] = {
          name: materialName,
        };

        this.geometries[geometryName] = {
          name: geometryName,
        }

        this.objects.push({
          name: name,
          position: position,
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          geometryName: geometryName,
          materialName: materialName,
        })
      }
    },
    mounted() {
      this.canvas = this.$refs.canvas;
      window.addEventListener('resize',this.resize)

      this.$refs.mat.inst.emissive = new THREE.Color( 0x23f660 )
      this.$refs.mat.inst.emissiveIntensity = 0.5
      this.$refs.mat.update()

      this.addSphere({x:-10,y:5,z:10})
      this.addSphere({x:-10,y:5,z:-10})
      this.addSphere({x:-10,y:5,z:-30})
      this.addSphere({x:-10,y:5,z:-50})
      this.addSphere({x:-10,y:5,z:-70})
      this.addSphere({x:-10,y:5,z:-90})


      this.objects.forEach(function(o,i) {

        const a = 2
        const tl = gsap.timeline({repeat: 0, repeatDelay: 0, yoyo: true})
        const f = function(tl) {
          tl.add(tl.fromTo(o.position,{y: a},{duration: 2, y: -a, ease: "sine.inOut"}))
          tl.add(tl.to(o.position,{duration: 2, y: a, ease: "sine.inOut", onComplete: f, onCompleteParams: [tl]}))
        }

        tl.fromTo(o.position,{y: 5,},{duration: 2, y: -a, ease: "sine.inOut"},i/3)
          .to(o.position,{duration: 2, y: a, ease: "sine.inOut", onComplete: f, onCompleteParams: [tl]})

      })

      window.addEventListener('wheel',this.onWheel)

    },
    beforeDestroy() {
      window.removeEventListener('wheel',this.onWheel)
      window.removeEventListener('resize',this.resize)
    }
  }
</script>

<style scoped>
  #canvas {
    width: 100vw!important;
    height: 100vh!important;
    position: fixed;
    z-index: -100;
    top: 0;
    left: 0;
  }

  .canvas {
    background-color: #A9E7DA;
    min-width: 100vw!important;
    min-height: 100vh!important;
    width: 400px;
    height: 247px;
    position: fixed;
    z-index: -100;
    top: 0;
    left: 0;
  }

  .canvas > canvas {
    position: fixed;
    top: 0;
    left: 0;
  }
</style>