<template>
  <!--<div class="home min-h-screen flex justify-center">-->
  <div class="home-background min-h-screen" v-on:mousemove="mousemove">


    <div class="home-header" >

      <svg height="0" width="0"  xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="svgPathHome" clipPathUnits="objectBoundingBox">
          <!--<clipPath id="svgPath" >-->
          <!--<path d="M1,0.8c0,0-0.173988,0.2-0.5,0.2S0,0.8,0,0.8V-1h1V0.8z"/>-->
            <!--<path d="M1,0.241607c0,0-0.173988,0.068303-0.500023,0.06830S0,0.24161,0,0.24161V-1h1V0.241607z"/>-->
            <path transform="scale(0.000521,0.000521)" id="shape" fill="none" d="M1919.76,0v799.47c-152.517-21.618-296.887-61.859-437.385-105.283C1074.93,568.26,814.93,554.26,402.243,698.119C269.008,744.563,141.991,790.201,0,807.19V0H1919.76z"/>
          </clipPath>
        </defs>
      </svg>

      <svg version="1.1" baseProfile="basic" class="hidden" id="Livello_1"
           xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1px" height="1px"
           viewBox="0 0 1 1" xml:space="preserve">
        <g>

          <path id="website" fill="none" d="M1919.76,0v799.47c-152.517-21.618-296.887-61.859-437.385-105.283C1074.93,568.26,814.93,554.26,402.243,698.119C269.008,744.563,141.991,790.201,0,807.19V0H1919.76z"/>
          <path id="mobileapp" fill="none" d="M1919.76,0v692.76c-173.43,58.62-333.9,121.14-529.34,121.14c-284,0-652.99-212.99-1011.98-212.99c-157.05,0-264.479,39.64-378.44,84.73V0H1919.76z"/>
          <path id="webapp" fill="none" d="M1919.76,0v640.7c-114.2-28.22-228.77-48.44-347.16-48.44c-440,0-684,221.64-1052.01,221.64C366.93,813.9,188.38,751.54,0,694.31V0H1919.76z"/>
          <path id="webgl" fill="none" d="M1920,0v559.245c-173.431,0-332.228,18.187-488.269,44.708c-156.041,26.521-309.326,61.376-471.731,94.711c-145.137,29.791-297.559,58.369-465.742,78.701C344.084,795.52,181.343,807.101,0,807.101V0h960H1920z"/>
        </g>
      </svg>

      <transition name="fade">
        <div v-if="shape == 'webGL'" class="w-full h-full absolute top-0 left-0 webgl">

          <webgl-sphere-scene v-if="!prerender" ref="webgl" :client-x="clientX" :client-y="clientY"></webgl-sphere-scene>

        </div>
      </transition>

      <transition name="fade">
        <div v-if="shape == 'webSite'" class="w-full h-full absolute top-0 left-0 website"></div>
      </transition>

      <transition name="fade">
        <div v-if="shape == 'webApp'" class="w-full h-full absolute top-0 left-0 webapp"></div>
      </transition>

      <transition name="fade">
        <div v-if="shape == 'mobileApp'" class="w-full h-full absolute top-0 left-0 mobile"></div>
      </transition>




    </div>




    <section class="page-content intro-section relative pb-0">

      <!--<svg class="z-100" width="305" height="289" viewBox="0 0 305 289" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--<path id="example" d="M152.5 0L188.31 110.212H304.194L210.442 178.326L246.252 288.538L152.5 220.424L58.7483 288.538L94.5582 178.326L0.806488 110.212H116.69L152.5 0Z" fill="#C4C4C4"/>-->
      <!--</svg>-->

      <h1 class="text-lg lg:text-xl"><strong>Gianluca Lomarco</strong>, sviluppatore web designer<br>
        realizzo: <strong>siti web, app mobile e web app</strong></h1>
    </section>
    <section class="page-content relative pt-0 pl-0 pr-0 md:pl-12 md:pr-12">
      <tab-service v-on:change-tab="morphSvg"></tab-service>

    </section>
    <section class="page-content relative pt-0">
      <reviews></reviews>

    </section>

    <!--<section class="page-content">-->
      <!--<websites></websites>-->
    <!--</section>-->

    <!--<section class="page-content">-->
      <!--<p><strong>Il web è un universo vastissimo costantemente in evoluzione.</strong> Per questo dedico tempo ed energie per scoprirlo e comprenderlo sempre di più e sempre meglio.</p>-->

      <!--<p><strong>Per me è come un viaggio alla scoprerta di nuovi mondi e nuove avventure.</strong></p>-->

      <!--<p><strong>Per te è la sicurezza di avere un professionista competente e affidabile.</strong></p>-->
    <!--</section>-->

    <section class="page-content">
      <keywords></keywords>
    </section>

    <section class="page-content">
      <websites></websites>
    </section>

    <contact-form page="home"></contact-form>


    <section class="page-content">
      <h3 class="text-xl lg:text-title font-bold mb-12 lg:mb-24">Cerchi i miei lavori?</h3>
      <p class="mb-10"><strong>Ecco perchè non troverai un portfolio dei miei lavori su questo sito.</strong></p>

      <ul class="grid grid-cols-1 col-gap-12">
        <li class="mb-10">
          <h3 class="font-bold text-lg text-blue mb-4">Ogni sito web è unico.</h3>
          <p class="text-caption">Ogni progetto nasce da un'idea. <strong>Realizzare un sito web sisgnifica sviluppare questa idea
            e dargli una forma grafica.</strong> Il primo errore da evitare è modellare l'idea in base a forme grafiche viste qua e là.</p>
        </li>
        <li class="mb-10">
          <h3 class="font-bold text-lg text-blue mb-4">Spalanca la tua creatività.</h3>
          <p class="text-caption"><strong>Pensa in grande!</strong> Il più delle volte, prendere spunto da altri progetti rischia di diventare
            un limite per la creatività, dando vita a prodotti poco originali e simili tra loro.</p>
        </li>
        <li class="mb-10">
          <h3 class="font-bold text-lg text-blue mb-4">Non ci sono limiti a ciò che possiamo realizzare insieme.</h3>
          <p class="text-caption">Ogni tuo desiderio è un ordine! Oramai sul web si può fare veramente di tutto. L'unico vero ostacolo
            è il budget che sarai disposto ad investire nella tua idea.</p>
        </li>
        <li class="mb-10">
          <h3 class="font-bold text-lg text-blue mb-4">Lasciati suggerire.</h3>
          <p class="text-caption">Spiegami la tua idea e lascia che sia io a mostrati i migliori esempi da cui prendere spunto.
          <strong>Ti eviterà una grossa perdita di tempo e la sicurezza di scegliere una soluzione conforme al tuo budget.</strong></p>
        </li>
        <!--<li class="mb-10">-->
        <!--<h3 class="font-bold text-lg text-blue">Il cliente ha sempre ragione.</h3>-->
        <!--<p class="text-caption">Zi badrone! Non c'é da ridere! Cerco sempre di indirizzare i miei clienti a prendere le decisioni-->
        <!--migliori, ma a volte questi non ne vogliono sapere.</p>-->
        <!--</li>-->
      </ul>
    </section>

    <section class="page-content">

      <div>
        <h3 class="text-xl lg:text-title font-bold mb-10">Competenze e strumenti</h3>
        <p>Se non capisci cosa sono queste scritte, ho una buona notizia per te: <strong>sei completamente sano!</strong></p>
      </div>
      <skills></skills>
    </section>


    <Footer></Footer>

  </div>



  <!--</div>-->
</template>

<script>
import TabService from '../components/TabService'
import Reviews from '../components/ReviewsCarousel'
import Websites from '../components/WebsitesCarousel'
import Keywords from '../components/Keywords'
import ContactForm from '../components/ContactForm'
import Skills from '../components/Skills'
import Footer from '../components/Footer'
import WebglSphereScene from '../components/webgl/scene/SphereSlide'
import { routerMixin } from "../mixins/routerMixin";
import { analyticsMixin } from "../mixins/analyticsMixin";
import { vuemetaMixin } from "../mixins/vuemetaMixin";

import { gsap } from 'gsap'
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'
gsap.registerPlugin(MorphSVGPlugin);


export default {
  name: 'Home',
  mixins: [routerMixin, analyticsMixin, vuemetaMixin],
  metaInfo() {
    return {
      title: this.meta.title,
      titleTemplate: ' %s | Gianluca Lomarco',
      meta: [
        { vmid: 'description', name: 'description', content: this.meta.description },
        { property: 'og:title', content: 'Gianluca Lomarco Web Designer e Sviluppatore Web'},
        { property: 'og:description', content: this.meta.description},
        { property: 'og:image', content: this.siteUrl+'/img/home-featured-image.jpg'},//todo create the image
        { property: 'og:url', content: this.siteUrl },
        { property: 'twitter:card', content: 'summary_large_image'},
        { property: 'og:site_name', content: this.siteName },
        { property: 'twitter:image:alt', content: 'Immagine di copertita di Gianluca Lomarco'},
      ],
      link: [
        { rel: 'canonical', href: this.siteUrl, id: 'canonical'}
      ]
    }
  },
  components: {
    TabService,
    Reviews,
    Websites,
    Keywords,
    ContactForm,
    Footer,
    Skills,
    WebglSphereScene
  },
  watch: {
    $route (to, from){
      //console.log(from +' => '+to)
      this.alignAirplane()
    },
  },
  data() {
    return {
      meta: {
        title: 'Web Designer e Sviluppatore',
        description: 'Sono Gianluca Lomarco, sviluppatore web e web designer: realizzo siti web, web app e app mobile per clienti in tutta Italia.'
      },
      shapes: {
        webSite: 'M0,0h1v0.416c-0.013,0-0.137-0.023-0.24-0.058S0.548,0.309,0.5,0.309S0.35,0.317,0.248,0.351S0.037,0.42,0,0.42V0z',
        webApp: 'M0,0h1v0.334c-0.067-0.018-0.148-0.03-0.24-0.022C0.654,0.32,0.55,0.359,0.5,0.377C0.453,0.394,0.35,0.429,0.248,0.423C0.173,0.418,0.044,0.375,0,0.362V0z',
        mobileApp: 'M0,0h1v0.36C0.962,0.372,0.852,0.414,0.76,0.421C0.654,0.43,0.551,0.389,0.5,0.374c-0.046-0.013-0.15-0.05-0.252-0.06C0.143,0.303,0.071,0.327,0,0.356V0z',
        webGL: 'M1920,0v807.101c-194.016,0-323.774,64.844-480.631,122.693c-126.314,46.585-270.201,88.634-479.369,88.634c-214.181,0-359.241-36.28-479.076-78.775C311.538,879.586,192.552,807.101,0,807.101V0H1920z',
      },
      shape: 'webSite',
      clientY: 0,
      clientX: 0,
      prerender: window.__prerender,
    }
  },
  methods: {
    mousemove(event) {
      this.clientX = event.clientX - window.innerWidth/2
      this.clientY = event.clientY - window.innerHeight*0.7
    },
    morphSvg(shape) {
      // console.log('morph shape')
      this.shape = shape

      switch (shape) {
        case 'webSite':
          gsap.to("#shape", { morphSVG:'#website',
            duration: 1,
            ease: "elastic.out(1, 0.3)",
          });
          break;
        case 'mobileApp':
          gsap.to("#shape", { morphSVG: '#mobileapp',
            duration: 1,
            ease: "elastic.out(1, 0.3)",
          });
          break;
        case 'webApp':
          gsap.to("#shape", {morphSVG:'#webapp',
            duration: 1,
            ease: "elastic.out(1, 0.3)",
          });
          break;
        case 'webGL':
          gsap.to("#shape", {morphSVG: {
              shape: '#webgl',
            },
            duration: 1
            ,
            ease: "elastic.out(1, 0.3)",
          });
          break;
      }
    }
  },
  created() {
    // gsap.to("#shape", {morphSVG:'#website', duration: 1});
  }

}
</script>

<style scoped>
  .home-header {
    position: absolute;
    width: 100vw;
    height: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    clip-path: url(#svgPathHome);
    -webkit-clip-path: url(#svgPathHome);
    pointer-events: none;
  }

  .intro-section {
    padding-top: 175px;
  }

  h1 {
    max-width: 720px;
    margin-bottom: 90px;
  }



  .website,.webapp, .mobile {
    background-size: 105%;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .website {
    background-image: url("/img/sfondo-siti-web-home.svg");
  }

  .webapp {
    background-image: url("/img/sfondo-web-app-home.svg");
  }

  .mobile {
    background-image: url("/img/sfondo-app-mobile-home.svg");
  }

  .webgl {
    background-image: none;
  }

  .fade-enter-active {
    transition: all 1s ease;
  }
  .fade-leave-active {
    transition: all 1s ease;
  }
  .fade-enter, .fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  @media only screen and (max-width: 779px) {
    .home-background {
      background-position: top right;
      background-size: auto;
    }

    .home-header {
      position: absolute;
      display: none;
      height: 100vw;

      width: 100vw;
      min-width: 1200px;
      clip-path: url(#svgPathHome);
      -webkit-clip-path: url(#svgPathHome);
      /*background-color: purple;*/
    }
    .home-background {
      background-image: url("/img/wave-homepage.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 1920px;
    }

  }

</style>
