<template>
  <div>
    <h2 class="text-xl lg:text-title font-bold mb-12 lg:mb-24">Cosa possiamo fare insieme:</h2>

    <carousel v-if="randomSites.length > 0" class="mb-12 lg:mb-16" :scrollPerPage="false" paginationActiveColor="#039BE5" :adjustableHeight="isMobile" :paginationEnabled="isMobile" :perPageCustom="[[350, 1], [768, 2], [1024, 2.5]]" :resistanceCoef="50" :loop="true">
      <slide v-for="(website,i) in randomSites" :key="website.id" class="slide">
        <img class="avatar-website" :src="website.icon ? website.icon : '/img/avatar.png'" alt="Foto avatar del sito web">

        <h4 class="font-bold hidden lg:block text-caption text-blue" >{{ titles[i] | replaceName(website.avatar) }}</h4>
        <h3 class="font-bold text-lg lg:text-xl py-6">{{ website.name }}</h3>
        <p class="text-caption mb-6 lg:mb-12">{{ website.excerpt }}</p>

        <router-link tag="button" :to="{ name: 'Website.show', params: {slug: website.id} }" class="bg-grey hover:bg-blue text-black hover:text-white text-button py-2 px-12 rounded-full" type="submit">scopri di più</router-link>
      </slide>
    </carousel>

    <p>
      <router-link class="text-blue font-bold text-lg" tag="a" :to="{ name: 'Websites' }">Vedi tutte le tipologie <heroicon class="mt-2" stroke="#000" stroke-width="2" width="35" name="arrow-right"></heroicon></router-link>
    </p>
  </div>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  import { mapGetters } from 'vuex'
  import { isMobile } from 'mobile-device-detect';

  export default {
    name: "WebsitesCarousel",
    components: {
      Carousel,
      Slide
    },
    data() {
      return {
        // websites: [],
        isMobile: isMobile,
        titles: [
          'Fai come %NAME%, che mi ha chiesto di realizzare il suo',
          'o come %NAME%, che mi ha chiesto di realizzare il suo',
          '%NAME%, invece, mi ha chiesto di realizzare un',
          'mentre %NAME% mi ha chiesto di realizzare il suo',
          'invece per %NAME% ho realizzato il suo',
          '%NAME% ha voluto che realizzassi un',
          'con %NAME% abbiamo realizzato il suo'
        ],
        colors: [
          'blue', 'salmon', 'blue', 'salmon', 'blue', 'salmon', 'blue', 'salmon',
        ]


      }
    },
    filters: {
      replaceName: function(val,name) {
        return val.replace('%NAME%',name);
      }
    },
    computed: {
      ...mapGetters({
          websites: 'websites'
      }),
      randomSites: function () {
        const orderedWebsites = [
          ...this.websites
        ]

        return orderedWebsites.sort(() => Math.random() - Math.random()).slice(0, this.titles.length);
      }
    },
    firestore: {
      // websites: db.collection('websites').orderBy('name'),
    },
  }
</script>

<style scoped>

  .slide {
    padding-right: 110px;
    cursor: -webkit-grab; cursor: grab;
  }

  .avatar-website {
    max-width: 130px;
    margin-bottom: 35px;
  }

  @media only screen and (max-width: 768px) {
    .slide {
      padding-right: 0px;
    }

    .avatar-website {
      max-width: 80px;
      margin-bottom: 20px;
    }
  }
</style>