<template>
  <div>

    <div class="mt-12">
      <h4 class="text-lg mb-4 font-light">Front end</h4>
      <p>
        <span class="text-caption md:text-lg font-bold block md:inline-block mr-0 md:mr-8 lg:mr-12 mb-8" v-for="skill in frontEndSkills" :key="skill.id">
          <img :src="skill.icon ? skill.icon : '/img/skill-shape.png'" alt="Icona skill front end" class="mr-6 inline skill-icon">
          {{ skill.name }}</span>
      </p>
    </div>

    <div class="mt-12">
      <h4 class="text-caption md:text-lg mb-4 font-light">Back end</h4>
      <p>
        <span class="text-caption md:text-lg font-bold block md:inline-block mr-0 md:mr-8 lg:mr-12 mb-8" v-for="skill in backEndSkills" :key="skill.id">
          <img :src="skill.icon ? skill.icon : '/img/skill-shape.png'" alt="Icona skill front end" class="mr-6 inline skill-icon">
          {{ skill.name }}</span>
      </p>
    </div>

    <div class="mt-12">
      <h4 class="text-lg mb-4 font-light">Framework</h4>
      <p>
        <span class="text-caption md:text-lg font-bold inline-block mr-12 mr-0 md:mr-8 lg:mr-12 relative mb-8" v-for="skill in frameworkSkills" :key="skill.id">
          <div class="skill-underline absolute" :class="'bg-'+skill.color"></div>
          {{ skill.name }}</span>
      </p>
    </div>

    <div class="mt-12">
      <h4 class="text-lg mb-4 font-light">CMS</h4>
      <p>
        <span class="text-caption md:text-lg font-bold inline-block mr-12 mr-0 md:mr-8 lg:mr-12 mb-4" v-for="skill in cmsSkills" :key="skill.id">
          {{ skill.name }}</span>
      </p>
    </div>

    <div class="mt-12">
      <h4 class="text-lg mb-4 font-light">Development</h4>
      <p>
        <span class="text-caption md:text-lg font-bold inline-block mr-12 mr-0 md:mr-8 lg:mr-12 mb-4" v-for="skill in devSkills" :key="skill.id">
          {{ skill.name }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  // import { db } from '../db'
  import { mapGetters } from 'vuex'

  export default {
    name: "Skills",
    // data() {
    //   return {
    //     skills: []
    //   }
    // },
    computed: {
      ...mapGetters({
        skills: 'skills'
      }),
      frontEndSkills: function() {
        return this.skills.filter( v => v.cat == 'Front end');
      },
      backEndSkills: function() {
        return this.skills.filter( v => v.cat == 'Back end');
      },
      frameworkSkills: function() {
        return this.skills.filter( v => v.cat == 'Framework');
      },
      cmsSkills: function() {
        return this.skills.filter( v => v.cat == 'CMS');
      },
      devSkills: function() {
        return this.skills.filter( v => v.cat == 'Development');
      }
    },
    // firestore: {
    //   skills: db.collection('skills'),
    // },
  }
</script>

<style scoped>

  .skill-icon {
    max-width: 50px;
  }

  .skill-underline {
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -15px;
  }

  @media only screen and (max-width: 780px) {
    .skill-icon {
      max-width: 40px;
    }
  }


</style>